console.log('🥑 %cMade by Monk', 'background: #616a2e; color: #f4e9e2; padding: 5px 17px; border-radius: 3px;');
console.log(' %chttp://monk.com.au ', 'padding: 5px 13px;');





jQuery(document).ready(function ($) { 

        //page
    var $hamburger  = $(".hamburger"),
        $site       = $("html,body"),
        $content    = $('.site-content'),
    
        //menu
        $menu       = $(".main-navigation"),
        $submenu    = $(".main-navigation"),
    
        //media
        $lightbox   = $('.lightbox'),
        $slider     = $('.feature.slider');
    
    
    //mobile menu
    $hamburger.on("click", function () {
        $hamburger.toggleClass("is-active"); 
        $site.toggleClass("menu-open");
    });
    //close menu with an outside click (basically anywhere on .site-content)
    $content.on('click', function() { 
        $site.removeClass("menu-open");
        $menu.removeClass("toggled");
        $submenu.removeClass('toggled-on');
        $hamburger.removeClass("is-active"); 
    });
    
    //lightbox
    //    $('.lightbox').lightGallery({
    //        selector: 'this'
    //    });
    //    
    //    $('.gallery').lightGallery({
    //        thumbnail:true
    //    }); 
    
    //add lightbox to image links
    $('a[href]').filter(function () {
        return this.href && this.href.match(/\.(?:jpe?g|gif|bmp|a?png)$/i); 
    }).addClass('lightbox');
    
    $lightbox.swipebox();
    
    
    //feature slider
    $slider.slick({
        accessibility: true,
        autoplay: true,
        draggable: true,
        infinite: true,
        pauseOnHover: false,
        swipe: true,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1, 
        arrows: false,
        fade: true
    });
    
    
    //change caption on slide change
    var slideCaption = function (event, slick, currentSlide, nextSlide) {

        var activeSlide = $slider.find('.slick-active'),
            slidedata = activeSlide.find('.slide'),
            caption = slidedata.data('caption'),
            tochange = $('.slide-caption');
        
        tochange.text(
            caption
        )
    }

    //show first caption on init
    slideCaption();
    //replace captions on slide change
    $slider.on('afterChange', slideCaption);

    //gforms submit button
    $('.gform_wrapper').on('submit', 'form', function(){

        $('[type=submit]', this)        // Select the form's submit button
            .val('Sending...')         // Change the value of the submit button. Change text to whatever you like.
            .prop('disabled', true);    // Not really necessary but will prevent the user from clicking the button again while the form is submitting.

    });
    
});